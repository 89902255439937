import payload_plugin_iD1SnIAnSpjrxq_KlzqxWf0xk_EfpfCXp1Wdn70pdqI from "/app/node_modules/@pinia/colada-nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_7k3FxwqWkK_kZlK_9MYz6mBBFR5gYeCkG918Y5EEzBM from "/app/node_modules/@pinia/colada-nuxt/dist/runtime/plugin.js";
import primevue_plugin_0tbz5Y3Ab_H50qf9PQhqyN3k7XWjmG9dWGLJNwEfVI0 from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_wp4kxkdwIclITZVcWkXPniTgFVTtTwZamTFNo05D70w from "/app/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import markdownit_JeLYIMyGcGsBcQ_QoLxd88vGLe_Qep2nEh8cM8c11Wc from "/app/plugins/markdownit.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/app/plugins/sentry.client.ts";
import vuedraggable_plugin_gACmcJFNl3xMip2zxHD0V88ZQ_KTufJg3YeAhxH5Xyk from "/app/plugins/vuedraggable-plugin.ts";
import vuelidate_CYd1wXfdCaeBaoni2I9AECOApHaHbjwGXioK02l_74c from "/app/plugins/vuelidate.ts";
export default [
  payload_plugin_iD1SnIAnSpjrxq_KlzqxWf0xk_EfpfCXp1Wdn70pdqI,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  plugin_7k3FxwqWkK_kZlK_9MYz6mBBFR5gYeCkG918Y5EEzBM,
  primevue_plugin_0tbz5Y3Ab_H50qf9PQhqyN3k7XWjmG9dWGLJNwEfVI0,
  plugin_client_wp4kxkdwIclITZVcWkXPniTgFVTtTwZamTFNo05D70w,
  markdownit_JeLYIMyGcGsBcQ_QoLxd88vGLe_Qep2nEh8cM8c11Wc,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  vuedraggable_plugin_gACmcJFNl3xMip2zxHD0V88ZQ_KTufJg3YeAhxH5Xyk,
  vuelidate_CYd1wXfdCaeBaoni2I9AECOApHaHbjwGXioK02l_74c
]