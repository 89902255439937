
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as tasksDSCLO0esxorEuQ5mjAnvztgyapMhzZqDpJ7_f96SzRwMeta } from "/app/pages/tasks.vue?macro=true";
import { default as agendaT7U4173aTQucYTJTl4kxrJy405vDfj04oxkFV1eB84UMeta } from "/app/pages/agenda.vue?macro=true";
import { default as indexM146nxsd_45YahYZ9IWPnjG_45RwvcJtgMdVqtl9B76LWxYMeta } from "/app/pages/commerce/sites/index.vue?macro=true";
import { default as index1TBkkIFyRCDe_biNzta4ySdhfv8_oStMoCivI4j1qM0Meta } from "/app/pages/commerce/contacts/index.vue?macro=true";
import { default as editJfupdMVJXSXDXMu4GKUZU_MJp2REdcZBeDXBYp9eWpkMeta } from "/app/pages/commerce/sites/[siteId]/edit.vue?macro=true";
import { default as preview2shEF5CkQLiP94AaYVWCwqYCQoeH6gW4pdcwW3lYUyoMeta } from "/app/pages/commerce/sites/[siteId]/preview.vue?macro=true";
import { default as contacts_45j5uYpvT6w2Jxa_45qYM_45Q7ECE5WaIslEmlKevoZNBJ2wMeta } from "/app/pages/commerce/sites/[siteId]/contacts.vue?macro=true";
import { default as relations1WG4xRvxk85rX4MX2JkyhHva1UvW5ZW4Y3cB_45htnEg8Meta } from "/app/pages/commerce/sites/[siteId]/relations.vue?macro=true";
import { default as _91siteId_93QwBV2NWjKbvASP4alXs8ZO7206UCBIiqvy5we0wdzFQMeta } from "/app/pages/commerce/sites/[siteId].vue?macro=true";
import { default as editSPv_45LpubLhB3cxYQq0kTMzBUrODLA1yFkvlfvHTUTUQMeta } from "/app/pages/commerce/contacts/[contactId]/edit.vue?macro=true";
import { default as siteswW1eyWgJ1ggOq_7Rtv1xYy_45GJ2U8GySYOmdP1626fTMMeta } from "/app/pages/commerce/contacts/[contactId]/sites.vue?macro=true";
import { default as preview2doHix_4559n5FiHoSrF4BPxE9sh0hPe5CjTcGRhHWIBQMeta } from "/app/pages/commerce/contacts/[contactId]/preview.vue?macro=true";
import { default as relationsKM4Lx_77vlJaxA_lazS1Z_450Mc3dCHLY6a_3wSjNYGNsMeta } from "/app/pages/commerce/contacts/[contactId]/relations.vue?macro=true";
import { default as _91contactId_93HTFrNjsZYFngLiiOW4EyVvkmix_YtXASJiKoP2aee8sMeta } from "/app/pages/commerce/contacts/[contactId].vue?macro=true";
import { default as commerceUhhyWRxhLgV8s2SG4_jH11WXOEp78B5x1UH5i9cJQN8Meta } from "/app/pages/commerce.vue?macro=true";
import { default as reportingF6x4kViyKcsqk2PDelfpy9i9KLFBFtICBSQTxqvFdGsMeta } from "/app/pages/reporting.vue?macro=true";
import { default as indexUTPWuOuTEHiifiH2gSDbviVYzY2wHbmPaccb9r89Io4Meta } from "/app/pages/admin/index.vue?macro=true";
import { default as notificationsB9uD7vf6BNbcGL3D9HpazycZMRkcPWG6ea6_45mQfWSE0Meta } from "/app/pages/notifications.vue?macro=true";
import { default as indexIWQa0IgSu7cy6lNZs_45TfU5FO6XNoRIlxtHKQUd551xIMeta } from "/app/pages/admin/banks/index.vue?macro=true";
import { default as index1zRBk23WUa9s_45mpb2B7JqoqGMudXvr8t2Lo0hMsfgCwMeta } from "/app/pages/admin/brands/index.vue?macro=true";
import { default as chargesx0s55JM2qY69hXCkxmuvErvAvGfCQ_45dgxdCa0LVJZuMMeta } from "/app/pages/simulation/charges.vue?macro=true";
import { default as incomesZ8iJFUHeDe7pdznHKn_pSLWlMZND3awxpfXYmVgQUB4Meta } from "/app/pages/simulation/incomes.vue?macro=true";
import { default as index55PkSyNw0jUt5u31_451eAirOD0PXx3Cte4qRZjv1aAxYMeta } from "/app/pages/opportunities/index.vue?macro=true";
import { default as chargestnSVSTn5lYblis3EPyM8Z5dEgbxIv6fosGpGwg_45qZRYMeta } from "/app/pages/opportunity/charges.vue?macro=true";
import { default as incomesdfr9V88XBEodrrs0xZ9njbtfvPgLeUMqlVJx4DSYM_45wMeta } from "/app/pages/opportunity/incomes.vue?macro=true";
import { default as _91bankId_93rpSdB_wfP_45yRcT50aKkVv8DdaMMQjOIxt1bRhI1JhhsMeta } from "/app/pages/admin/banks/[bankId].vue?macro=true";
import { default as index9KNi0gKILxY7UuBKE_VW_7g89sJxGm_45pcpmXrKMP0OoMeta } from "/app/pages/admin/positions/index.vue?macro=true";
import { default as _91brandId_93Vw3T4eBgBHuwfejUYUYWvSxaEXi5h4Vt45T421LJM_YMeta } from "/app/pages/admin/brands/[brandId].vue?macro=true";
import { default as indexCuv63lI2SvJSS03MIlhdOpwG_45wPeEX7ucMe_45fYJdUNEMeta } from "/app/pages/admin/charge_types/index.vue?macro=true";
import { default as indexfBhdd6Ih_45g6OqocUbkgn0Uc4MxcW0s9aG2w6UFnMq7oMeta } from "/app/pages/admin/credit_types/index.vue?macro=true";
import { default as indexOLCYP2CpWeW0CfB0NuQbfqFQwF6wyT4QTXgx_kqFFm4Meta } from "/app/pages/admin/income_types/index.vue?macro=true";
import { default as indexDh0mdEeoqpEabm5mG4c8pSrSh3Ypc_w1CcYjHlZr6MkMeta } from "/app/pages/admin/bank_products/index.vue?macro=true";
import { default as indexQhXMK1JLPIcvPJlp8VzjrtiZbW_72ryTY_YHUCiML_QMeta } from "/app/pages/admin/insurance_types/index.vue?macro=true";
import { default as indexW5Bx1aWVuyHtBGylJn6wNu8ELcDOrZLZL6h3bhgcqVMMeta } from "/app/pages/admin/legal_capacities/index.vue?macro=true";
import { default as _91positionId_93d8j9PqO4SwDEpG2hF7_45rRDmDhX9NW_451KVhxcpH_45OKF4Meta } from "/app/pages/admin/positions/[positionId].vue?macro=true";
import { default as index_45Q1mECiYEejd8aBg5M7oZjTpb1_wL5PQwbQgBQBeMyIMeta } from "/app/pages/settings/notifications/index.vue?macro=true";
import { default as indexPs5ZnnvsBBoAZxty5XEJfLV6dT2hlq1LR7VnVQfoGB0Meta } from "/app/pages/admin/contact_positions/index.vue?macro=true";
import { default as indexQ2QoJIj_45xUBXthiRqUkm9Q5Ao_45rOSIxYOJ_Ejdz03gQMeta } from "/app/pages/admin/excluded_documents/index.vue?macro=true";
import { default as indexiIdKt3n302VqiBPlxhhybAz0_5fnQc06BY6eLs2syQkMeta } from "/app/pages/admin/contact_event_types/index.vue?macro=true";
import { default as indexplGoHnff0G5UqySXjP372lAguDzZ7OtRB1_jVm1XlQsMeta } from "/app/pages/admin/cash_requirement_goal/index.vue?macro=true";
import { default as _91ChargeTypeId_93WIeiwKGDMB15XVHsfgTrQzIW_45rFWyW0zYG0PiJ_45lFbMMeta } from "/app/pages/admin/charge_types/[ChargeTypeId].vue?macro=true";
import { default as _91creditTypeId_93netNLhsi6y9D2D20Qk5xFbRvSOLs5es_9Iq9Zc30unoMeta } from "/app/pages/admin/credit_types/[creditTypeId].vue?macro=true";
import { default as _91incomeTypeId_93mfPIGIwk2qKmX1f0SjhoIGy_AUFz41faOcTLhnqOnowMeta } from "/app/pages/admin/income_types/[incomeTypeId].vue?macro=true";
import { default as editFfAyXCPULifBTEQJPy4QmcyUh9rWPcndCHfGBdA8H_QMeta } from "/app/pages/opportunities/[opportunityHashId]/edit.vue?macro=true";
import { default as historyXgtceBgDMUnyNQkefZjpcMM5yXNZYQPiRpG95ufFuAEMeta } from "/app/pages/opportunities/[opportunityHashId]/history.vue?macro=true";
import { default as previewS5WK_45SsVDTn_45lP3fcm5qBDkqazVOJk1MQWOIu6xYcZcMeta } from "/app/pages/opportunities/[opportunityHashId]/preview.vue?macro=true";
import { default as index5W_s1iuVcf6aJnVy7pqCNt_45skkPPdqsg4aw9zdmIJeMMeta } from "/app/pages/opportunities/[opportunityHashId]/analysis/index.vue?macro=true";
import { default as _91bankAccountId_93Xbm01ENaXmbBOJ_45xx9jATweratbJhQMabQWnPsNb_7gMeta } from "/app/pages/opportunities/[opportunityHashId]/analysis/bank-accounts/[bankAccountId].vue?macro=true";
import { default as _91bankAccountStatementId_93r1ZymAWvUUYHwRmxIZ0fNLearDE1Q6Sv0Pv5qWK_45zz4Meta } from "/app/pages/opportunities/[opportunityHashId]/analysis/bank-account-statements/[bankAccountStatementId].vue?macro=true";
import { default as analysisAKQQtEDcUyqSeBUnfXeXuGqEehVH2YRaU259xD84ImYMeta } from "/app/pages/opportunities/[opportunityHashId]/analysis.vue?macro=true";
import { default as deletedA_45ZFJowZJ5OoF2890jvlyP0Zw1Olg1KsTZFtTOJK10AMeta } from "/app/pages/opportunities/[opportunityHashId]/messages/deleted.vue?macro=true";
import { default as receivedgCU476r6Hm92WWfiV0B8Oll_Y_45XXdmTgZWlHIvVcRLAMeta } from "/app/pages/opportunities/[opportunityHashId]/messages/received.vue?macro=true";
import { default as generatedK42VuRKD1m9I_XF2Cub9zC90mEnUZbiQnBPqBu2JwH8Meta } from "/app/pages/opportunities/[opportunityHashId]/messages/generated.vue?macro=true";
import { default as messagesZtZvDqqPdjKU0Qd5BnSxwJN2CaXdEJ_Kr1H86TcPTucMeta } from "/app/pages/opportunities/[opportunityHashId]/messages.vue?macro=true";
import { default as all11Sj6Vuoe6gBG0UbKUSMNIJOYYXdokJNGm9y4j6b1X8Meta } from "/app/pages/opportunities/[opportunityHashId]/documents/all.vue?macro=true";
import { default as deleted6fbDg2aD4B_45qNeMotlRluZFDofUhfv9JCW5bmQ8Y450Meta } from "/app/pages/opportunities/[opportunityHashId]/documents/deleted.vue?macro=true";
import { default as documentsmbSd_n24kYkkz9mSTMIbR8e73wNrriumBX2UiWXTZNYMeta } from "/app/pages/opportunities/[opportunityHashId]/documents.vue?macro=true";
import { default as srm6C9qhQLWCL4mwkI3og8NLEhOWPAiIeDCz1DgZgV4inoMeta } from "/app/pages/opportunities/[opportunityHashId]/simulation/srm.vue?macro=true";
import { default as autoQi3OGr0MMa6I5Yj_45HtuKKImVVaBDiyPX4K21TyL_4wsMeta } from "/app/pages/opportunities/[opportunityHashId]/simulation/auto.vue?macro=true";
import { default as advancedX0ff4i6tq6ye8VEePhNg5_AOXinQahip6KW8V6myWaMMeta } from "/app/pages/opportunities/[opportunityHashId]/simulation/advanced.vue?macro=true";
import { default as _91simulationLegacyId_93xLjHEq8xxGe7KKxBqg9ieWL61tSy01By1Ryc7gYSR54Meta } from "/app/pages/opportunities/[opportunityHashId]/simulation/blink/[simulationLegacyId].vue?macro=true";
import { default as simulationvyf0y5dBsReMSC8YG98o117dJCvFpGa_51qdum3j1eIMeta } from "/app/pages/opportunities/[opportunityHashId]/simulation.vue?macro=true";
import { default as _91opportunityHashId_93pk15iCnpQFE_fkoHwlkEH7Ufh4tZuczWpy1M0nxbD1QMeta } from "/app/pages/opportunities/[opportunityHashId].vue?macro=true";
import { default as _91bankProductId_93bGHTKa9eRwrZRmjcNjdBdRcDuJdS12AcZEBcXjANvsMMeta } from "/app/pages/admin/bank_products/[bankProductId].vue?macro=true";
import { default as indexpS78U8OMWcRO8DTmx_45Vx2kzfvTv3M8NZ1DMqYFZZuPoMeta } from "/app/pages/admin/bank_product_commissions/index.vue?macro=true";
import { default as _91insuranceTypeId_93ekOt4KsjIzi9yZ_45RvjHGYbgytgzi2YUDv6_45gdbeEeiEMeta } from "/app/pages/admin/insurance_types/[insuranceTypeId].vue?macro=true";
import { default as _91legalCapacityId_939d_dwEjBhs5Ay5Il_Mk_45sRvcCswI1N0XFAVLiAxqfuUMeta } from "/app/pages/admin/legal_capacities/[legalCapacityId].vue?macro=true";
import { default as _91contactPositionId_93xscVjtucgZgUixFi5twNGEHoU4ih_45iPL4Q2kTbfKfqIMeta } from "/app/pages/admin/contact_positions/[contactPositionId].vue?macro=true";
import { default as _91excludedDocumentId_93jJY4_mz0gpgYoOkfP9NxtDNLX_45s9S3k04USJnkjy6w8Meta } from "/app/pages/admin/excluded_documents/[excludedDocumentId].vue?macro=true";
import { default as _91ContactEventTypeId_93t28DlbsIHHgowEh24N8nhCkaTF9vuLfdyRhfE27c7uYMeta } from "/app/pages/admin/contact_event_types/[ContactEventTypeId].vue?macro=true";
import { default as _91cashRequirementGoalId_93e5EufJ1wM0sgPOBAg_45MrnwcY2qn_45LwKbKvpPxF_45a4H8Meta } from "/app/pages/admin/cash_requirement_goal/[cashRequirementGoalId].vue?macro=true";
import { default as _91bankProductCommissionId_93z_45Xoy2YOlxhsmWBU_45scGdAIHzfHmB1OvtgE_uRr8cNcMeta } from "/app/pages/admin/bank_product_commissions/[bankProductCommissionId].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/app/pages/tasks.vue")
  },
  {
    name: "agenda",
    path: "/agenda",
    component: () => import("/app/pages/agenda.vue")
  },
  {
    name: "commerce",
    path: "/commerce",
    component: () => import("/app/pages/commerce.vue"),
    children: [
  {
    name: "commerce-sites",
    path: "sites",
    component: () => import("/app/pages/commerce/sites/index.vue")
  },
  {
    name: "commerce-contacts",
    path: "contacts",
    component: () => import("/app/pages/commerce/contacts/index.vue")
  },
  {
    name: "commerce-sites-siteId",
    path: "sites/:siteId()",
    component: () => import("/app/pages/commerce/sites/[siteId].vue"),
    children: [
  {
    name: "commerce-sites-siteId-edit",
    path: "edit",
    component: () => import("/app/pages/commerce/sites/[siteId]/edit.vue")
  },
  {
    name: "commerce-sites-siteId-preview",
    path: "preview",
    component: () => import("/app/pages/commerce/sites/[siteId]/preview.vue")
  },
  {
    name: "commerce-sites-siteId-contacts",
    path: "contacts",
    component: () => import("/app/pages/commerce/sites/[siteId]/contacts.vue")
  },
  {
    name: "commerce-sites-siteId-relations",
    path: "relations",
    component: () => import("/app/pages/commerce/sites/[siteId]/relations.vue")
  }
]
  },
  {
    name: "commerce-contacts-contactId",
    path: "contacts/:contactId()",
    component: () => import("/app/pages/commerce/contacts/[contactId].vue"),
    children: [
  {
    name: "commerce-contacts-contactId-edit",
    path: "edit",
    component: () => import("/app/pages/commerce/contacts/[contactId]/edit.vue")
  },
  {
    name: "commerce-contacts-contactId-sites",
    path: "sites",
    component: () => import("/app/pages/commerce/contacts/[contactId]/sites.vue")
  },
  {
    name: "commerce-contacts-contactId-preview",
    path: "preview",
    component: () => import("/app/pages/commerce/contacts/[contactId]/preview.vue")
  },
  {
    name: "commerce-contacts-contactId-relations",
    path: "relations",
    component: () => import("/app/pages/commerce/contacts/[contactId]/relations.vue")
  }
]
  }
]
  },
  {
    name: "reporting",
    path: "/reporting",
    component: () => import("/app/pages/reporting.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/app/pages/notifications.vue")
  },
  {
    name: "admin-banks",
    path: "/admin/banks",
    component: () => import("/app/pages/admin/banks/index.vue")
  },
  {
    name: "admin-brands",
    path: "/admin/brands",
    component: () => import("/app/pages/admin/brands/index.vue")
  },
  {
    name: "simulation-charges",
    path: "/simulation/charges",
    meta: chargesx0s55JM2qY69hXCkxmuvErvAvGfCQ_45dgxdCa0LVJZuMMeta || {},
    component: () => import("/app/pages/simulation/charges.vue")
  },
  {
    name: "simulation-incomes",
    path: "/simulation/incomes",
    meta: incomesZ8iJFUHeDe7pdznHKn_pSLWlMZND3awxpfXYmVgQUB4Meta || {},
    component: () => import("/app/pages/simulation/incomes.vue")
  },
  {
    name: "opportunities",
    path: "/opportunities",
    component: () => import("/app/pages/opportunities/index.vue")
  },
  {
    name: "opportunity-charges",
    path: "/opportunity/charges",
    meta: chargestnSVSTn5lYblis3EPyM8Z5dEgbxIv6fosGpGwg_45qZRYMeta || {},
    component: () => import("/app/pages/opportunity/charges.vue")
  },
  {
    name: "opportunity-incomes",
    path: "/opportunity/incomes",
    meta: incomesdfr9V88XBEodrrs0xZ9njbtfvPgLeUMqlVJx4DSYM_45wMeta || {},
    component: () => import("/app/pages/opportunity/incomes.vue")
  },
  {
    name: "admin-banks-bankId",
    path: "/admin/banks/:bankId()",
    component: () => import("/app/pages/admin/banks/[bankId].vue")
  },
  {
    name: "admin-positions",
    path: "/admin/positions",
    component: () => import("/app/pages/admin/positions/index.vue")
  },
  {
    name: "admin-brands-brandId",
    path: "/admin/brands/:brandId()",
    component: () => import("/app/pages/admin/brands/[brandId].vue")
  },
  {
    name: "admin-charge_types",
    path: "/admin/charge_types",
    component: () => import("/app/pages/admin/charge_types/index.vue")
  },
  {
    name: "admin-credit_types",
    path: "/admin/credit_types",
    component: () => import("/app/pages/admin/credit_types/index.vue")
  },
  {
    name: "admin-income_types",
    path: "/admin/income_types",
    component: () => import("/app/pages/admin/income_types/index.vue")
  },
  {
    name: "admin-bank_products",
    path: "/admin/bank_products",
    component: () => import("/app/pages/admin/bank_products/index.vue")
  },
  {
    name: "admin-insurance_types",
    path: "/admin/insurance_types",
    component: () => import("/app/pages/admin/insurance_types/index.vue")
  },
  {
    name: "admin-legal_capacities",
    path: "/admin/legal_capacities",
    component: () => import("/app/pages/admin/legal_capacities/index.vue")
  },
  {
    name: "admin-positions-positionId",
    path: "/admin/positions/:positionId()",
    component: () => import("/app/pages/admin/positions/[positionId].vue")
  },
  {
    name: "settings-notifications",
    path: "/settings/notifications",
    component: () => import("/app/pages/settings/notifications/index.vue")
  },
  {
    name: "admin-contact_positions",
    path: "/admin/contact_positions",
    component: () => import("/app/pages/admin/contact_positions/index.vue")
  },
  {
    name: "admin-excluded_documents",
    path: "/admin/excluded_documents",
    component: () => import("/app/pages/admin/excluded_documents/index.vue")
  },
  {
    name: "admin-contact_event_types",
    path: "/admin/contact_event_types",
    component: () => import("/app/pages/admin/contact_event_types/index.vue")
  },
  {
    name: "admin-cash_requirement_goal",
    path: "/admin/cash_requirement_goal",
    component: () => import("/app/pages/admin/cash_requirement_goal/index.vue")
  },
  {
    name: "admin-charge_types-ChargeTypeId",
    path: "/admin/charge_types/:ChargeTypeId()",
    component: () => import("/app/pages/admin/charge_types/[ChargeTypeId].vue")
  },
  {
    name: "admin-credit_types-creditTypeId",
    path: "/admin/credit_types/:creditTypeId()",
    component: () => import("/app/pages/admin/credit_types/[creditTypeId].vue")
  },
  {
    name: "admin-income_types-incomeTypeId",
    path: "/admin/income_types/:incomeTypeId()",
    component: () => import("/app/pages/admin/income_types/[incomeTypeId].vue")
  },
  {
    name: "opportunities-opportunityHashId",
    path: "/opportunities/:opportunityHashId()",
    component: () => import("/app/pages/opportunities/[opportunityHashId].vue"),
    children: [
  {
    name: "opportunities-opportunityHashId-edit",
    path: "edit",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/edit.vue")
  },
  {
    name: "opportunities-opportunityHashId-history",
    path: "history",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/history.vue")
  },
  {
    name: "opportunities-opportunityHashId-preview",
    path: "preview",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/preview.vue")
  },
  {
    name: analysisAKQQtEDcUyqSeBUnfXeXuGqEehVH2YRaU259xD84ImYMeta?.name,
    path: "analysis",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/analysis.vue"),
    children: [
  {
    name: "opportunities-opportunityHashId-analysis",
    path: "",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/analysis/index.vue")
  },
  {
    name: "opportunities-opportunityHashId-analysis-bank-accounts-bankAccountId",
    path: "bank-accounts/:bankAccountId()",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/analysis/bank-accounts/[bankAccountId].vue")
  },
  {
    name: "opportunities-opportunityHashId-analysis-bank-account-statements-bankAccountStatementId",
    path: "bank-account-statements/:bankAccountStatementId()",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/analysis/bank-account-statements/[bankAccountStatementId].vue")
  }
]
  },
  {
    name: "opportunities-opportunityHashId-messages",
    path: "messages",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/messages.vue"),
    children: [
  {
    name: "opportunities-opportunityHashId-messages-deleted",
    path: "deleted",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/messages/deleted.vue")
  },
  {
    name: "opportunities-opportunityHashId-messages-received",
    path: "received",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/messages/received.vue")
  },
  {
    name: "opportunities-opportunityHashId-messages-generated",
    path: "generated",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/messages/generated.vue")
  }
]
  },
  {
    name: "opportunities-opportunityHashId-documents",
    path: "documents",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/documents.vue"),
    children: [
  {
    name: "opportunities-opportunityHashId-documents-all",
    path: "all",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/documents/all.vue")
  },
  {
    name: "opportunities-opportunityHashId-documents-deleted",
    path: "deleted",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/documents/deleted.vue")
  }
]
  },
  {
    name: "opportunities-opportunityHashId-simulation",
    path: "simulation",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/simulation.vue"),
    children: [
  {
    name: "opportunities-opportunityHashId-simulation-srm",
    path: "srm",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/simulation/srm.vue")
  },
  {
    name: "opportunities-opportunityHashId-simulation-auto",
    path: "auto",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/simulation/auto.vue")
  },
  {
    name: "opportunities-opportunityHashId-simulation-advanced",
    path: "advanced",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/simulation/advanced.vue")
  },
  {
    name: "opportunities-opportunityHashId-simulation-blink-simulationLegacyId",
    path: "blink/:simulationLegacyId()",
    component: () => import("/app/pages/opportunities/[opportunityHashId]/simulation/blink/[simulationLegacyId].vue")
  }
]
  }
]
  },
  {
    name: "admin-bank_products-bankProductId",
    path: "/admin/bank_products/:bankProductId()",
    component: () => import("/app/pages/admin/bank_products/[bankProductId].vue")
  },
  {
    name: "admin-bank_product_commissions",
    path: "/admin/bank_product_commissions",
    component: () => import("/app/pages/admin/bank_product_commissions/index.vue")
  },
  {
    name: "admin-insurance_types-insuranceTypeId",
    path: "/admin/insurance_types/:insuranceTypeId()",
    component: () => import("/app/pages/admin/insurance_types/[insuranceTypeId].vue")
  },
  {
    name: "admin-legal_capacities-legalCapacityId",
    path: "/admin/legal_capacities/:legalCapacityId()",
    component: () => import("/app/pages/admin/legal_capacities/[legalCapacityId].vue")
  },
  {
    name: "admin-contact_positions-contactPositionId",
    path: "/admin/contact_positions/:contactPositionId()",
    component: () => import("/app/pages/admin/contact_positions/[contactPositionId].vue")
  },
  {
    name: "admin-excluded_documents-excludedDocumentId",
    path: "/admin/excluded_documents/:excludedDocumentId()",
    component: () => import("/app/pages/admin/excluded_documents/[excludedDocumentId].vue")
  },
  {
    name: "admin-contact_event_types-ContactEventTypeId",
    path: "/admin/contact_event_types/:ContactEventTypeId()",
    component: () => import("/app/pages/admin/contact_event_types/[ContactEventTypeId].vue")
  },
  {
    name: "admin-cash_requirement_goal-cashRequirementGoalId",
    path: "/admin/cash_requirement_goal/:cashRequirementGoalId()",
    component: () => import("/app/pages/admin/cash_requirement_goal/[cashRequirementGoalId].vue")
  },
  {
    name: "admin-bank_product_commissions-bankProductCommissionId",
    path: "/admin/bank_product_commissions/:bankProductCommissionId()",
    component: () => import("/app/pages/admin/bank_product_commissions/[bankProductCommissionId].vue")
  }
]