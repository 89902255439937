import { defineStore } from 'pinia';
import { computed } from 'vue';
import type { NavigationFailure, RouteLocationRaw } from 'vue-router';
import type { RuntimeConfig } from '@nuxt/schema';
import { navigateTo, useCookie, useRuntimeConfig } from '#app';

export const useAuthStore = defineStore('auth', () => {
  const config: RuntimeConfig = useRuntimeConfig();

  const authCookie = useCookie<string | null>('auth_token', {
    secure: true,
    sameSite: 'none',
    maxAge: 8640,
  });

  const isAuthenticated = computed(() => Boolean(authCookie.value));

  function setToken (newToken: string): void {
    authCookie.value = newToken;
  }

  function clearToken (): void {
    authCookie.value = null;
  }

  function fetchToken (): Promise<void | NavigationFailure | false> | false | void | RouteLocationRaw {
    try {
      return navigateTo(`${config.public.API_ROOT_URL}/auth/redirect`, { external: true });
    } catch (error) {
      console.error('Error during token fetch:', error);
      return false;
    }
  }

  return {
    authCookie,
    isAuthenticated,
    setToken,
    clearToken,
    fetchToken,
  };
});
