import { useAuthStore } from '~/stores/auth';
import { navigateTo, useRuntimeConfig } from '#app';

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore();
  const config = useRuntimeConfig();

  if (to.query.token && typeof to.query.token === 'string') {
    authStore.setToken(to.query.token);
    const { token, ...query } = to.query;
    return navigateTo({ path: to.path, query }, { replace: true });
  }

  if (!authStore.authCookie) {
    const fetched = await authStore.fetchToken();
    if (!fetched || !authStore.isAuthenticated) {
      return navigateTo(
        `${config.public.API_ROOT_URL}/auth/redirect`,
        { external: true },
      );
    }
  }
});
