import { useAuthStore } from '@/stores/auth';

import { useFetch, useRuntimeConfig } from '#app';

export const useAuthFetch = async (url, params = {}) => {
  const authStore = useAuthStore();
  const config = useRuntimeConfig();

  const opts = {
    key: url,
    baseURL: config.public.API_BASE_URL,
    headers: {
      Authorization: authStore.authCookie ? `Bearer ${authStore.authCookie}` : '',
      ...(params.headers || {}),
    },
    onRequest ({ options }) {
      // eslint-disable-next-line no-param-reassign
      options.headers = options.headers || {};
      if (authStore.authCookie) {
        // eslint-disable-next-line no-param-reassign
        options.headers.Authorization = `Bearer ${authStore.authCookie}`;
      }
    },
    onResponse ({ response }) {
      return response._data;
    },
    ...params,
  };

  const { data, error, execute } = await useFetch(url, opts);

  if (error.value?.statusCode === 401) {
    await authStore.fetchToken();
  }

  const errors = error?.value?.data?.errors || [];
  const mainError = errors.length ? errors[0] : null;

  return { data, error, execute, errors, mainError };
};
